import React, { ReactNode } from 'react'

import './center.scss'

interface CenterProps {
    children: ReactNode
}

const Center = ( props: CenterProps ) => {
    const
        { children } = props

    return (
        <div className="center-container">
            { children }
        </div>
    )
}

export default Center
