import classNames           from 'classnames'
import React, { ReactNode } from 'react'

interface WProps {
    children?: ReactNode,
    className?: string,
    extraClassName: string
}

const
    Wrapper = ({ extraClassName, ...props }: WProps ) =>
        (
            <div {...props} className={classNames( extraClassName, props.className )}>
                { props.children }
            </div>
        )

export default Wrapper
