import { LoginForm }       from '@tabee/frontend-auth'
import React, { useState } from 'react'

import {
    Layout,
    Center,
    AccountContainer,
    SignMethods,
    AccountFooter,
    SocialNetworks
} from 'components'
import { t, strings } from 'tools'

import LoginLinks from './LoginLinks'
import './login.scss'

interface LoginData {
    data: object,
    message: string,
    status: boolean,
    token: string,
    user: {
        id: string
    }
}

interface LoginProps {
    onSuccess: ( token: string, account: string ) => void
}

const Login = ( props: LoginProps ) => {
    const
        { onSuccess } = props,
        [ msg, $msg ] = useState( '' ),
        [ auth2fa, $auth2fa ] = useState( true ),
        callback = ( event: LoginData ) => {
            if ( event.token ) {
                onSuccess( event.token, event.user.id )
            } else {
                if ( event?.data && typeof event.data === 'object' ) {
                    $msg( Object.values( event.data )[ 0 ])
                } else {
                    $msg( 'Unknown error' )
                }
            }
        }

    return (
        <Layout className="login">
            <Center>

                <SignMethods />

                <AccountContainer title={t( 'auth.loginTitle' )}>

                    <LoginForm
                        withRemember

                        errorMessage = { msg }
                        i18nPrefix = 'auth'
                        inputClassName = 'dark-theme'

                        signInType = { auth2fa ? '2fa' : 'default' }
                        translator = { t }

                        onResult = { callback }
                    />

                    <LoginLinks />

                </AccountContainer>

                <span className="link" onClick={() => $auth2fa( !auth2fa )}>
                    {
                        auth2fa
                            ? t( 'auth.SignInWithPasswordInstead' )
                            : t( 'auth.SignInWithCode' )
                    }
                </span>

                <AccountFooter>
                    <SocialNetworks />
                </AccountFooter>
            </Center>
        </Layout>
    )
}

export default Login
