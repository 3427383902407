import classNames from 'classnames'

import { InlineSvg } from 'components'

import './loader.scss'


interface LoaderProps {
    visible?: boolean
}

const Loader = ({ visible }: LoaderProps ) => {
    return (
        <InlineSvg className={classNames( 'public-loader', 'center-container', visible && 'is-visible' )} url="/loader.svg" />
    )
}

export default Loader
