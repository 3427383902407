import axios                from 'axios'
import config               from 'config'
import { AxiosEvent }       from 'models'
import React, { useEffect } from 'react'
import { useParams }        from 'react-router-dom'


const
    Pixel = () => {
        const
            api = process.env.REACT_APP_API,
            params = useParams(),

            onSuccess = ( token: string | null, account: string | null ) => {
                localStorage.setItem( 'sso-token', token || '' )
                localStorage.setItem( 'sso-account', account ?? '' )
            },

            { user, token } = params

        useEffect(() => {
            axios.get(
                api + '/api/users',
                config.axiosConfig( token || '' )
            ).then(( event: AxiosEvent ) => {
                ( event.status === 200 ) && onSuccess( token || '', user || '' )
            })
        }, [])

        return (
            <div className="sso-pixel"></div>
        )
    }

export default Pixel
