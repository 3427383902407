import config from 'config'

const prepareURL = ( url: string | null ) => url && url !== '/' && decodeURIComponent( url ) !== '/' ? `https://${url.replace( /^(http)?(s)?:\/\//, '' )}` : config.defaultRedirect

const params = () => {
    const
        urlParams = new URLSearchParams( window.location.search ),

        signOut = urlParams.has( 'signout' ),
        originalRedirect = urlParams.get( 'redirect_uri' ),
        redirect = prepareURL( originalRedirect )

    return {
        redirect,
        originalRedirect,
        signOut,
        urlParams
    }
}

export { params }
