import { SignUpForm }                 from '@tabee/frontend-auth'
import axios                          from 'axios'
import config                         from 'config'
import { AxiosEvent }                 from 'models'
import React, { useState, useEffect } from 'react'

import {
    Layout,
    Center,
    AccountContainer,
    SignMethods,
    AccountFooter,
    SocialNetworks,
    Loader
} from 'components'
import { t, extract } from 'tools'


interface SignUpData {
    data: object,
    message: string,
    status: boolean,
    token: string,
    user: {
        id: string
    }
}

const SignUp = () => {
    const
        api = process.env.REACT_APP_API,
        token = localStorage.getItem( 'sso-token' ),
        { originalRedirect, redirect } = extract.params(),

        [ msg, $msg ] = useState( '' ),
        [ checking, $checking ] = useState( true ),

        onFail = () => {
            $checking( false )
        },

        onSuccess = ( token: string | null, account: string | null ) => {
            localStorage.setItem( 'sso-token', token || '' )
            localStorage.setItem( 'sso-account', account ?? '' )

            window.location.href = `${decodeURIComponent( redirect ?? '' )}?user=${account}&hash=${token}`
        },

        onIsAuthorized = () => {
            if ( originalRedirect ) {
                onSuccess( token, localStorage.getItem( 'sso-account' ))
            } else {
                window.location.href = '/'
            }
        },

        callback = ( event: SignUpData ) => {
            if ( event.token ) {
                onSuccess( event.token, event.user.id )
            } else {
                $msg( 'error' )
            }
        }

    useEffect(() => {
        if ( token ) {
            axios.get(
                api + '/api/users',
                config.axiosConfig( token )
            ).then(( event: AxiosEvent ) => {
                ( event.status === 200 )
                    ? onIsAuthorized()
                    : onFail()
            }).catch(() => {
                localStorage.removeItem( 'sso-token' )
                localStorage.removeItem( 'sso-account' )

                onFail()
            })
        } else {
            onFail()
        }
    }, [])

    return (
        <Layout className="signup">
            <Center>

                <SignMethods />

                <AccountContainer title={t( 'auth.registerTitle' )}>

                    {
                        checking
                            ? (
                                <Center>
                                    <Loader visible />
                                </Center>
                            )
                            : (
                                <SignUpForm
                                    errorMessage = { msg }
                                    i18nPrefix = 'auth'
                                    inputClassName = 'dark-theme'

                                    signInType = '2fa'
                                    translator = { t }

                                    onResult = { callback }
                                />
                            )
                    }

                </AccountContainer>

                <AccountFooter>
                    <SocialNetworks />
                </AccountFooter>
            </Center>
        </Layout>
    )
}

export default SignUp
