import cn                      from 'classnames'
import { useEffect, useState } from 'react'

interface IInlineSvgProps {
    className?: string
    url: string
}

const InlineSvg = ( props: IInlineSvgProps ) => {
    const
        [ svg, $svg ] = useState<string>(),
        [ loaded, $loaded ] = useState( false ),
        [ error, $error ] = useState( false )

    useEffect(() => {
        fetch( props.url )
            .then( res => res.text())
            .then( html => $svg( html ))
            .catch( $error )
            .then(() => $loaded( true ))
    }, [ props.url ])

    if ( !svg ) {
        return null
    }

    return (
        <div
            className={cn( 'svgInline', `svgInline--${ loaded ? 'loaded' : 'loading'}`, error && 'svgInline--errored', props.className )}
            dangerouslySetInnerHTML={{ __html: svg }}
        />
    )
}

export default InlineSvg
