import config from 'config'

import './logo.scss'

const Logo = () => (
    <div className="app-logo">
        <img
            alt={config.appName}
            className="image"
            height="32"
            src="/logo.png"
            width="180"
        />
    </div>
)

export default Logo
