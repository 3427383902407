import { LoadingOutlined }                 from '@ant-design/icons'
import { Spin }                            from 'antd'
import React, { ReactNode, CSSProperties } from 'react'

interface SpinnerProps {
    children?: ReactNode,
    className?: string,
    color?: string,
    size?: 'default' | 'small' | 'large',
    style?: CSSProperties
}


const
    getSize = ( size: string | undefined ) => {
        switch ( size ) {
            case 'small':
                return 12
            case 'large':
                return 32
            default:
                return 24
        }
    },

    Spinner = ({ style, size, color, className }: SpinnerProps ) => (
        <Spin
            className = { className }
            size = { size }
            style = { style }
            indicator = { (
                <LoadingOutlined
                    spin
                    style={{
                        fontSize:   getSize( size ),
                        lineHeight: 1,
                        color
                    }}
                />
            ) }
        />
    )

export default Spinner
