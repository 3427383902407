import { Button }               from 'antd'
import { TError, ErrorPayload } from 'models'
import React                    from 'react'

import { Layout, Center } from 'components'

import './error.scss'

interface ErrorProps {
    code?: number,
    text?: string,
    error?: TError,
    payload?: ErrorPayload,
    buttonText?: string,
    buttonDisabled?: boolean,
    buttonClick?: () => void
}

const Error = ( props: ErrorProps ) => {
    const
        { buttonText, buttonDisabled, buttonClick } = props,
        { code } = props.error ? props.error : props,
        text = props.error ? props.error.message( props.payload ) : props.text

    return (
        <Layout className="error">
            <Center>
                {
                    code && code > -1
                        ? (
                            <h2 className="error-code">
                                Error
                                { code ? `: ${code}` : '' }
                            </h2>
                        )
                        : (
                            <h2 className="error-code">
                                { props.error?.header?.() }
                            </h2>
                        )
                }

                <div className="error-content">
                    { text }
                </div>

                {
                    buttonText && (
                        <Button className="ghost" disabled={buttonDisabled} onClick={buttonClick}>
                            { buttonText }
                        </Button>
                    )
                }
            </Center>
        </Layout>
    )
}

export default Error
