interface ErrorPayload {
    [key: string]: string
}

interface TError {
    code: number,
    header?: ( payload?: ErrorPayload ) => string,
    message: ( payload?: ErrorPayload ) => string
}

interface TErrorList {
    [key: string]: TError
}

const TErrors: TErrorList = {
    invalidRedirect: {
        code:    4031,
        message: ( payload?: ErrorPayload ) => `"${payload?.redirect}" is not a valid redirect`
    },
    confirmCodeWrong: {
        code:    4032,
        message: () => 'Code is wrong or outdated'
    },
    sourceIsMalformed: {
        code:    4061,
        message: () => 'Source is malformed'
    },
    alreadySent: {
        code:    -1,
        header:  () => 'Code succesfully sent.',
        message: () => 'Check your email.'
    }
}

export type { ErrorPayload, TError }

export default TErrors
