import classNames                              from 'classnames'
import React, { ReactNode, MouseEventHandler } from 'react'

import { UserImage } from 'components'

import './card.scss'

interface CardProps {
    className?: string,
    link?: string,
    name: string,
    company?: string,
    avatar?: string | null,
    fallback?: string | null,
    icon?: ReactNode | null,
    onClick: MouseEventHandler
}

const Card = ( props: CardProps ) => {
    const
        { className, icon, avatar, name } = props,
        cls = classNames(
            'card',
            className
        ),
        contentCls = classNames(
            'name',
        )

    return (
        <div className={cls} title={name} onClick={props.onClick}>
            {
                icon
                    ? (
                        <span className="icon">
                            { icon }
                        </span>
                    )
                    :
                    avatar && (
                        <span className="icon">
                            <UserImage fallback={props.fallback ?? void 0} src={avatar} />
                        </span>
                    )
            }

            <span className={contentCls}>
                {name}
                {' '}

                { props.company && (
                    <span className="company-name">
(
                        {props.company}
)
                    </span>
                ) }
            </span>
        </div>
    )
}

export default Card
