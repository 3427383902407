import config from 'config'
import moment from 'moment'
import React  from 'react'

import { Icons, LanguageSwitch } from 'components'
import { t }                     from 'tools'


import './social-networks.scss'

const SocialNetworks = () => (
    <div className="social-networks-wrap">

        <nav className="site-navigation">
            <div className="legal">
                <span>{ t( 'global.copyright', { year: moment().format( 'YYYY' ) }) }</span>
            </div>
        </nav>

        <LanguageSwitch />

        <div className="social-networks">
            <div className="social-networks-label">
                { t( 'global.followUs' ) }
            </div>

            <div className="social-networks-items">
                <a className="social-network" href={config.links.facebook}>
                    <Icons.Facebook extraClassName="social-network-icon" />
                </a>

                <a className="social-network" href={config.links.twitter}>
                    <Icons.Twitter extraClassName="social-network-icon" />
                </a>
            </div>
        </div>
    </div>
)

export default SocialNetworks
