import classNames           from 'classnames'
import React, { ReactNode } from 'react'

import { Logo } from 'components'

import './account-container.scss'

interface ACProps {
    children: ReactNode,
    title?: string | null,
    description?: string | null
}

const AccountContainer = ({ children, title, description }: ACProps ) => (
    <div className="account-container">
        <div className="account-header">
            <div className="logo">
                <Logo />
            </div>

            {
                title && (
                    <div className={classNames( 'page-title', 'title-fix' )}>
                        { title }
                    </div>
                )
            }

            {
                description && (
                    <div className={classNames( 'title-fix', 'small' )}>
                        { description }
                    </div>
                )
            }
        </div>

        <div className="account-content">
            { children }
        </div>
    </div>
)

export default AccountContainer
