import React from 'react'

import { Image } from 'components'

import './user-image.scss'

interface UserImageProps {
    src: string,
    fallback?: string | undefined,
    fullName?: string
}

const
    defaultFallback = 'https://tabee.app/files/placeholder_avatar@2x.jpg',
    UserImage = ({ src, fullName, fallback }: UserImageProps ) => (
        <span className="user-image">
            <Image
                alt = { fullName }
                className = "image"
                fallback = {fallback ? [ fallback, defaultFallback ] : defaultFallback }

                height = {48}
                src = { src }
                width = {48}
            />
        </span>
    )

export default UserImage
