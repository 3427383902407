export const uniq = () => '_' + Math.random().toString( 36 ).substr( 2, 9 )

export const joinparams = ( arr: Array<string | null | undefined | false>, prepend = '&' ) => {
    const
        res = arr.filter( s => !!s ).join( '&' )

    if ( res ) {
        return `${prepend}${res}`
    }

    return ''
}
