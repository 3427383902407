import config from 'config'
import React  from 'react'

import { Icons } from 'components'
import { t }     from 'tools'

import './sign-methods.scss'

/* TODO: Make links working */

interface SMProps {
    hideSignUp?: boolean,
    noSignUp?: boolean
}

const SignMethods = ({ hideSignUp, noSignUp }:SMProps ) => (
    <div className='sign-methods'>
        {
            noSignUp
                ? null
                : !hideSignUp
                    ? (
                        <div className='registration'>
                            { t( 'auth.dontHaveAccount' ) }
                            {' '}
                            <a href={config.links.signup}>{ t( 'auth.loginSignUp' ) }</a>
.
                        </div>
                    )
                    : (
                        <div className='registration'>
                            { t( 'register.haveAccount' ) }
                            {' '}
                            <a href={config.links.login}>{ t( 'register.registerSignIn' ) }</a>
.
                        </div>
                    )
        }

        <div className='apps'>
            <a className='app' href={config.links.ios}>
                <Icons.IOSApp extraClassName='app-icon' />

                <span>
                    { t( 'global.getIOS' ) }
                </span>
            </a>

            <a className='app' href={config.links.android}>
                <Icons.AndroidApp extraClassName='app-icon' />

                <span>
                    { t( 'global.getAndroid' ) }
                </span>
            </a>
        </div>
    </div>
)

export default SignMethods
