import { Menu, Dropdown }                        from 'antd'
import classNames                                from 'classnames'
import React, { ReactNode, useState, useEffect } from 'react'

import { Icons }      from 'components'
import { t, i18next } from 'tools'

import './language-switch.scss'

interface LSProps {
    className?: string,
    hide?: boolean
}

interface LangDef {
    [key: string]: any
}

const LanguageSwitch = ( props: LSProps ) => {
    const
        { hide } = props,
        [ open, $open ] = useState( false ),
        setLang = ( lang: string ) => () => {
            i18next.changeLanguage( lang )
            $open( false )
        },
        items = () => {
            const
                res: ReactNode[] = [],
                langs = i18next.options.resources || {}

            Object.keys( langs ).forEach( lang => {
                const
                    langData:LangDef = langs[ lang ]

                res.push(
                    <Menu.Item key={lang}>
                        <span onClick={setLang( lang )}>
                            { langData.main.global.language }
                        </span>
                    </Menu.Item>
                )
            })

            return res
        },
        menu = (
            <Menu
                className = { classNames( 'dark-theme', 'lang-dropdown', props.className ) }
                selectedKeys = {[ i18next.language ]}
            >
                { items() }
            </Menu>
        )


    useEffect(() => {
        ( hide ) && ( $open( false ))
    }, [ hide ])

    return (
        <Dropdown
            overlay = { menu }
            placement = "top"
            trigger = {[ 'click' ]}
            visible = { open }

            onVisibleChange = { $open }
        >
            <span className="lang-switch">
                { t( 'global.language' ) }
                <Icons.World />
            </span>
        </Dropdown>
    )
}

export default LanguageSwitch
