import classNames                                  from 'classnames'
import config                                      from 'config'
import React, { useState, useEffect, useCallback } from 'react'

import { Spinner } from 'components'


import './image.scss'

interface ImageProps {
    className?: string,
    src: string,
    fallback?: string | ( string )[],
    alt?: string,
    resize?: boolean,
    width: number,
    height: number,
    onFail?: () => void,
}

const
    extmap: Record<string, string> = {
        'jpg':  'jpeg',
        'jpeg': 'jpeg',
        'png':  'png'
    },

    mediate = ( src: string ) => (
        src.substr( 0, 2 ) === '//' || src.substr( 0, 4 ) === 'http' || src.substr( 0, 5 ) === 'data:'
            ? src
            : `${config.images.media}/${src}`
    ),

    Image = ({ width = 100, height = 100, resize = true, src, onFail, className, fallback, alt }:ImageProps ) => {
        const
            [ loading, $loading ] = useState( !!src ),
            [ error, $error ] = useState( false ),
            [ fbindex, $fbindex ] = useState( 0 ),

            fallbacks = ( Array.isArray( fallback )
                ? [ ...fallback, config.images.latestFallback ]
                : fallback ? [ fallback, config.images.latestFallback ] : [ config.images.latestFallback ]).filter( fb => !!fb ),
            cls = classNames( 'image-container', className ),
            source = src
                ? mediate( src )
                : '',
            rfb = fallbacks[ fbindex ],
            fb = mediate( rfb ),

            fbp = fb.substr( 0, 2 ) === '//'
                ? `${window.location.protocol}${fb}`
                : fb,

            ext = src ? extmap[ ( src.split( '.' ).pop() ?? '' ).toLowerCase() ] : extmap[ fbp.split( '.' ).pop() ?? '' ],

            url = ( error || !src )
                ? (
                    resize
                        ? `${config.images.resizer}/${width * 2}x${height * 2},${ext}/${fbp}`
                        : fbp
                )
                : (
                    resize
                        ? `${config.images.resizer}/${width * 2}x${height * 2},${ext}/${source}`
                        : source
                )

        useEffect(() => {
            $error( false )
            $loading( true )

            ;( !src ) && ( $loading( false ))
        }, [ src ])

        useEffect(() => {
            if ( fbindex >= fallbacks.length ) {
                $loading( false )
                ;( onFail ) && ( onFail())
            }
        }, [ fbindex ])

        return (
            <div className={cls}>
                <img
                    alt = { alt }
                    className = { loading ? 'loading' : '' }
                    src = { error ? fbp : url }

                    onError = { () => { error ? $fbindex( fbindex + 1 ) : $error( true ) }}
                    onLoad = { () => setTimeout(() => $loading( false ), 0 )}
                />

                {
                    loading && (
                        <div className="image-spinner">
                            <Spinner />
                        </div>
                    )
                }
            </div>
        )
    }

export default Image
