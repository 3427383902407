import TabeeAuth                        from '@tabee/frontend-auth'
import { useState, useEffect }          from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Confirm, Main, SignUp, Pixel, SameUser, AuthCheck, Validate } from 'pages'
import { i18next, extract }                                            from 'tools'

import './app.scss'

const
    App = () => {
        const
            api = process.env.REACT_APP_API,
            token = localStorage.getItem( 'sso-token' ),
            { originalRedirect } = extract.params(),
            [ _, $lang ] = useState( 'default' ),
            [ authSet, $authSet ] = useState( false )

        i18next.on( 'languageChanged', ( lang: string ) => $lang( lang ))

        useEffect(() => {
            TabeeAuth.setConfig({
                api,
                project: process.env.REACT_APP_PROJECT,
                token
            })

            $authSet( true )

            window.addEventListener( 'focus', () => {
                if ( localStorage.getItem( 'sso-token' ) && !!originalRedirect ) {
                    window.location.reload()
                }
            })
        }, [])

        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route element={<Main />} path="/" />
                        <Route element={<SignUp />} path="/sign-up" />
                        <Route element={<Pixel />} path="/pixel/:user/:token" />
                        <Route element={<Confirm />} path="/:type/confirm/:code" />
                        <Route element={<SameUser />} path="/same-user/:account" />
                        <Route element={<AuthCheck authSet={authSet} />} path="/auth/:mode" />
                        <Route element={<Validate />} path="/validate/:token" />
                    </Routes>
                </BrowserRouter>
            </div>
        )
    }

export default App

