import classNames           from 'classnames'
import React, { ReactNode } from 'react'

import './layout.scss'

interface LayoutProps {
    children: ReactNode,
    className: string
}

const Layout = ( props: LayoutProps ) => {
    const
        { children, className } = props,
        cls = classNames( 'sso-layout', className )

    return (
        <div className={cls}>
            { children }
        </div>
    )
}

export default Layout
