import React, { useEffect } from 'react'
import { useParams }        from 'react-router-dom'

const
    SameUser = () => {
        const
            params = useParams(),
            user = localStorage.getItem( 'sso-account' )

        useEffect(() => {
            if ( params.account && user !== params.account ) {
                ( window.parent ) && ( window.parent.postMessage( JSON.stringify({
                    sameUser: false,
                    userId:   user
                }), '*' ))
            }
        }, [])

        return <></>
    }

export default SameUser
